import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import textStyles from '@styles/textStyles.module.css';
import * as styles from './TestimonialCardWide.module.css';

import Actions from '@components/Actions';
import BaseCard from '@components/BaseCard';
import Icon from '@components/Icon';

import TextRenderer from '@components/TextRenderer';
import Illustration from '@components/Illustration';
import Image from '@components/Image';
import SanityImage from '@components/SanityImage';

const DynamicSection = ({ heading, items }) => (
  <div className={styles.section}>
    <h3 className={cn(styles.sectionHeading, textStyles.eyebrow)}>{heading}</h3>
    <ul className={styles.sectionList}>
      {items.map((item, index) => (
        <li key={index} className={styles.sectionItem}>
          {item.icon && (
            <Icon icon={item.icon} className={styles.sectionItemIcon} />
          )}
          <span className={textStyles.bodyL}>{item.text}</span>
        </li>
      ))}
    </ul>
  </div>
);

const TestimonialCardWide = ({
  quote,
  author,
  authorTitle,
  companyLogo,
  sections = [],
  actions,
  illustration,
  gradient,
  background,
}) => {
  return (
    <BaseCard
      className={styles.root}
      tag="figure"
      theme="light"
      gradient={gradient}
      gradientShape="radial"
      background={background}
    >
      {illustration && (
        <div className={styles.illustration} aria-hidden>
          <Illustration illustration={illustration} />
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.mainContent}>
          {companyLogo && companyLogo.src && (
            <Image
              className={styles.logo}
              src={companyLogo.src}
              alt={companyLogo.alt}
              sizes="150px"
            />
          )}
          {companyLogo && !companyLogo.src && (
            <SanityImage
              className={styles.logo}
              asset={companyLogo.image.asset}
              alt={companyLogo.alt}
              maxWidth={150}
              maxHeight={35}
              sizes="150px"
            />
          )}

          <blockquote className={styles.quote}>
            <TextRenderer
              className={cn(styles.quoteText, textStyles.headlineM)}
              text={`“${quote}”`}
            />
            <footer className={styles.footer}>
              {author && <div className={cn(styles.authorName)}>{author}</div>}
              {authorTitle && (
                <div className={cn(styles.authorPosition)}>{authorTitle}</div>
              )}
            </footer>
          </blockquote>
        </div>
        <div className={styles.sections}>
          {sections.map((section, index) => (
            <DynamicSection key={index} {...section} />
          ))}
        </div>
      </div>
      {actions && actions.length > 0 && (
        <div className={styles.actions}>
          <Actions
            actions={actions}
            className={styles.actionLink}
            overwrites={{ colorScheme: 'text' }}
          />
        </div>
      )}
    </BaseCard>
  );
};

DynamicSection.propTypes = {
  heading: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

TestimonialCardWide.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  authorTitle: PropTypes.string,
  companyLogo: PropTypes.object.isRequired,
  companyName: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.string,
          text: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['primary', 'secondary', 'link']),
    }),
  ),
  illustration: PropTypes.string,
  gradient: PropTypes.string,
};

export default TestimonialCardWide;
