import { AwardListing } from './AwardListing';
import { CardCarousel } from './CardCarousel';
import { CardList } from './CardList';
import { CardRow } from './CardRow';
import { IconTextList, TextList } from './IconTextList';
import { ProgressionCardList } from './ProgressionCardList';
import { FeatureSectionWithImage } from './FeatureSectionWithImage';
import { FeatureList } from './FeatureList';
import {
  PrimaryHeroWithIllustration,
  PrimaryHeroWithImage,
  SecondaryHeroWithIllustration,
  TertiaryHero,
  FormatHeroWithTerminal,
  FormatHeroWithCallToAction,
  FormatHeroWithIllustration,
  IntegrationPageHero,
  HomepageHero,
  HomepageHeroAnimated,
  BlogArticleHero,
  CampaignHeroWithForm,
  CampaignHeroWithHubSpotForm,
  CampaignHeroWithResource,
  MarketingHero,
  SwitchHero,
  BlogLandingPageHero,
  NewHeroHubSpotForm,
  ProductHero,
} from './Hero';

import { TeaserCardGrid, CaseStudyListing } from './TeaserCardGrid';
import { TypeListing } from './TypeListing';
import {
  IllustrationMediaBlock,
  ImageMediaBlock,
  FrameMediaBlock,
  VideoMediaBlock,
} from './MediaBlock';
import {
  IllustrationWithText,
  ImageWithText,
  FrameWithText,
} from './MediaWithText';
import { FormatsIntegrationBlock } from './FormatsIntegrationBlock';
import { LiveData } from './LiveData';
import { Accordion } from './Accordion';
import { PricingTable } from './PricingTable';
import { PricingCards } from './PricingCards';
import { Text } from './Text';
import { RichText } from './RichText';
import { Resources } from './Resources';
import { CallToActionCard } from './CallToActionCard';
import { Quote } from './Quote';
import { Divider } from './Divider';
import { SolutionCardList } from './SolutionCardList';
import { ContactCards } from './ContactCards';
import { PackagesBlock } from './PackagesBlock';
import { FeaturedPressRelease } from './FeaturedPressRelease';
import { PressReleasesList } from './PressReleasesList';
import { PricingCardsWithTable } from './PricingCardsWithTable';
import { ProductOverview } from './ProductOverview';
import { WebinarList } from './WebinarList';
import { FeatureComparisonBlock } from './FeatureComparisonBlock';
import { InfoTiles } from './InfoTiles';
import { CaseStudyTeaser } from './CaseStudyTeaser';

const PageComponents = {
  AwardListing,
  CardList,
  CardRow,
  CardCarousel,
  CaseStudyTeaser,
  FeatureComparisonBlock,
  FeaturedPressRelease,
  FeatureSectionWithImage,
  FeatureList,
  IconTextList,
  ProgressionCardList,
  TeaserCardGrid,
  IllustrationMediaBlock,
  ImageMediaBlock,
  InfoTiles,
  FrameMediaBlock,
  VideoMediaBlock,
  IllustrationWithText,
  ImageWithText,
  FrameWithText,
  TypeListing,
  FormatsIntegrationBlock,
  LiveData,
  Accordion,
  PressReleasesList,
  PricingTable,
  PricingCards,
  PricingCardsWithTable,
  ProductOverview,
  Text,
  RichText,
  TextList,
  Resources,
  CallToActionCard,
  CaseStudyListing,
  Quote,
  Divider,
  SolutionCardList,
  ContactCards,
  PackagesBlock,
  WebinarList,
};

const HeroComponents = {
  PrimaryHeroWithIllustration,
  PrimaryHeroWithImage,
  SecondaryHeroWithIllustration,
  TertiaryHero,
  HomepageHero,
  HomepageHeroAnimated,
  ProductHero,
  FormatHeroWithTerminal,
  FormatHeroWithCallToAction,
  FormatHeroWithIllustration,
  MarketingHero,
  SwitchHero,
  IntegrationPageHero,
  BlogLandingPageHero,
  BlogArticleHero,
  CampaignHeroWithForm,
  CampaignHeroWithHubSpotForm,
  CampaignHeroWithResource,
  NewHeroHubSpotForm,
};

export default PageComponents;
export { HeroComponents };
