import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { withPageSection } from '@components/PageSection';
import WistiaVideoPlayer from '@components/WistiaVideoPlayer';
import Icon from '@components/Icon';
import Actions from '@components/Actions';
import SanityImage from '@components/SanityImage';
import textStyles from '@styles/textStyles.module.css';

import * as styles from './CaseStudyTeaser.module.css';

const Section = ({ heading, contentType, textContent, listItems }) => {
  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>{heading}</div>
      {contentType === 'text' ? (
        <p className={styles.sectionContent}>{textContent}</p>
      ) : (
        <ul className={styles.sectionList}>
          {listItems.map((item, index) => (
            <li key={index} className={styles.sectionListItem}>
              {item.icon && (
                <Icon icon={item.icon} className={styles.sectionListItemIcon} />
              )}
              {item.text}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const CaseStudyTeaser = withPageSection(
  ({ quote, companyLogo, author, wistiaVideo, contentSections, actions }) => {
    return (
      <>
        <div className={styles.teaserContent}>
          <div className={styles.header}>
            {companyLogo && (
              <SanityImage
                className={styles.logo}
                asset={companyLogo.image.asset}
                alt={companyLogo.alt}
                maxWidth={150}
                maxHeight={30}
                sizes="150px"
              />
            )}

            <blockquote
              className={cn(textStyles.headlineM, styles.quote)}
              dangerouslySetInnerHTML={{ __html: quote }}
            />
            <div className={styles.author}>
              <p className={styles.authorName}>{author.name}</p>
              <p className={styles.authorTitle}>{author.title}</p>
            </div>
          </div>

          <div className={styles.video}>
            <WistiaVideoPlayer
              id={wistiaVideo.id}
              title={wistiaVideo.title}
              thumbnail={wistiaVideo.thumbnail}
            />
          </div>
        </div>

        {Array.isArray(contentSections) && (
          <div className={styles.teaserSections}>
            {contentSections.map((section, index) => (
              <Section {...section} key={index} />
            ))}
          </div>
        )}

        {actions && actions.length > 0 && (
          <div className={styles.teaserActions}>
            <Actions actions={actions} />
          </div>
        )}
      </>
    );
  },
  {
    className: styles.root,
    theme: 'dark',
    actions: null,
  },
);

CaseStudyTeaser.propTypes = {
  quote: PropTypes.string.isRequired,
  companyLogo: PropTypes.object,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  wistiaVideo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    thumbnail: PropTypes.object.isRequired,
  }).isRequired,
  contentSections: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      contentType: PropTypes.oneOf(['text', 'list']).isRequired,
      textContent: PropTypes.string,
      listItems: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.string,
          text: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['primary', 'secondary', 'link']),
    }),
  ),
  gradient: PropTypes.string,
};

export default CaseStudyTeaser;
