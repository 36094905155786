import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Actions from '@components/Actions';
import TextRenderer from '@components/TextRenderer';

import textStyles from '@styles/textStyles.module.css';
import flowStyles from '@styles/utilities/flow.module.css';
import * as styles from './CardWithMedia.module.css';

const CardWithMedia = ({
  heading,
  renderHeadlineAs = 'h4',
  text,
  actions,
  size = 'medium',
  media,
  mediaAspectRatio,
}) => {
  const Headline = renderHeadlineAs;

  const style = {
    '--media-aspect-ratio':
      mediaAspectRatio ?? (size === 'large' ? '628 / 340' : '411 / 280'),
  };

  return (
    <div className={styles.root} data-size={size} style={style}>
      <div className={styles.content}>
        <Headline
          className={cn(
            styles.heading,
            size === 'large' ? textStyles.headlineS : textStyles.headlineXS,
          )}
        >
          {heading}
        </Headline>
        <TextRenderer
          className={cn(
            styles.text,
            textStyles.bodyM,
            flowStyles.root,
            flowStyles.xsmall,
          )}
          text={text}
        />
        {actions && (
          <Actions
            actions={actions}
            className={styles.actions}
            overwrites={{ type: 'link', colorScheme: 'text', withArrow: true }}
          />
        )}
      </div>
      <div className={styles.media}>{media}</div>
    </div>
  );
};

CardWithMedia.propTypes = {
  heading: PropTypes.string.isRequired,
  renderHeadlineAs: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['medium', 'large']),
  media: PropTypes.node.isRequired,
};

export default CardWithMedia;
