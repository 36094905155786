import cn from 'classnames';

import CardWithMedia from '@components/CardWithMedia';
import { withPageSection } from '@components/PageSection';
import SanityImage from '@components/SanityImage';

import styles from './CardRow.module.css';
import gridStyles from '@styles/layouts/grid.module.css';
import flowStyles from '@styles/utilities/flow.module.css';
import Explainer from '@components/Explainer';

export const CardRow = withPageSection(
  ({ items, theme }) => {
    return (
      <div
        className={cn(
          styles.root,
          gridStyles.row,
          flowStyles.root,
          flowStyles.small,
        )}
      >
        {items.map((item, i) => {
          const isFirstRow = i < 2;

          let media = null;

          if (item.image) {
            media = (
              <SanityImage
                className={styles.image}
                asset={item.image.image.asset}
                alt={item.image.alt}
                fill
              />
            );
          } else if (item.explainer) {
            media = (
              <Explainer
                explainer={item.explainer}
                className={styles.explainer}
              />
            );
          }

          return (
            <div
              className={cn(
                styles.column,
                gridStyles.column,
                isFirstRow && styles.firstRow,
              )}
              key={i}
            >
              <CardWithMedia
                heading={item.title}
                text={item.description}
                actions={item.actions}
                media={media}
                mediaAspectRatio={item.mediaAspectRatio}
                theme={theme}
                size={isFirstRow ? 'large' : 'medium'}
              />
            </div>
          );
        })}
      </div>
    );
  },
  {
    layout: 'vertical',
  },
);
