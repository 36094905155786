import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import { colorMap, colorNames } from '@constants/colors';

import Gradient from './Gradient';
import * as styles from './BaseCard.module.css';

const BaseCard = ({
  children,
  className,
  gradient,
  tag = 'div',
  theme,
  gradientShape = 'shapes',
  background,
}) => {
  const cssClasses = cn(styles.root, {
    [styles.themeLight]: theme === 'light',
    [styles.themeDark]: theme === 'dark',
    [styles.gradientRadial]: gradient && gradientShape === 'radial',
  });

  const Tag = tag;

  const style = {};
  if (background) style['--card-background'] = colorMap[background];
  if (gradient) style['--card-gradient'] = colorMap[gradient];

  return (
    <Tag className={cssClasses} style={style}>
      {theme && gradient && gradientShape === 'shapes' && (
        <div className={styles.gradient} aria-hidden>
          <Gradient fill={gradient} />
        </div>
      )}
      <div className={cn(styles.children, className)}>{children}</div>
    </Tag>
  );
};

BaseCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  gradient: PropTypes.oneOf(colorNames),
  tag: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  noBorderRadius: PropTypes.bool,
};

export default BaseCard;
