import cn from 'classnames';

import { withPageSection } from '@components/PageSection';

import * as styles from './FeatureComparisonBlock.module.css';
import TickIcon from '@assets/icons/Tick.svg';
import CloseIcon from '@assets/icons/Close.svg';
import InfoIcon from '@assets/icons/InfoFilled.svg';

const HoverInfo = ({ children }) => {
  return (
    <div className={cn(styles.info)}>
      <InfoIcon />
      <div className={cn(styles.hoverText)}>{children}</div>
    </div>
  );
};

const HeaderCell = ({ title, highlight = false }) => {
  return (
    <div className={cn(styles.headerCell, highlight && styles.highlight)}>
      {title}
    </div>
  );
};

const FeatureCell = ({ value, valueType, hoverText }) => {
  let content = null;
  let isFilled = false;

  if (valueType === 'text' && value && value !== '') {
    isFilled = true;
    content = (
      <div className={styles.text}>
        {value.split('\n').map((line, i) => (
          <div className={styles.line} key={i}>
            {line}
          </div>
        ))}
      </div>
    );
  } else if (valueType === 'checkmark') {
    isFilled = true;
    content = <TickIcon className={styles.tick} />;
  } else if (valueType === 'cross') {
    content = <CloseIcon className={styles.cross} />;
  }

  return (
    <div className={cn(styles.featureCell, isFilled ? styles.isFilled : null)}>
      {content}
      {hoverText && <HoverInfo>{hoverText}</HoverInfo>}
    </div>
  );
};

const FeatureRow = ({ title, value1, value2, value3, numComparisons }) => {
  return (
    <div className={styles.featureRow}>
      <div className={styles.featureTitle}>{title}</div>
      <FeatureCell {...value1} />
      <FeatureCell {...value2} />
      {numComparisons > 1 && <FeatureCell {...value3} />}
    </div>
  );
};

export const FeatureComparisonBlock = withPageSection(
  ({
    comparison1,
    comparison2,
    comparison3,
    numComparisons = 1,
    features = [],
  }) => {
    const headerTitles = [comparison1, comparison2, comparison3];
    if (numComparisons === 1) {
      headerTitles.pop();
    }

    return (
      <div className={cn(styles.root)}>
        <div className={cn(styles.header)}>
          <div className={cn(styles.spacer)}></div>
          {headerTitles.map((title, i) => (
            <HeaderCell title={title} highlight={i === 0} key={`header-${i}`} />
          ))}
        </div>
        <div className={cn(styles.rows)}>
          {features.map((feature, i) => (
            <FeatureRow
              {...feature}
              numComparisons={numComparisons}
              key={`feature-${i}`}
            />
          ))}
        </div>
      </div>
    );
  },
);
