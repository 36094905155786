import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import PlayIcon from '@icons/Play.svg';
import Image from '@components/Image';
import SanityImage from '@components/SanityImage';

import * as styles from './WistiaVideoPlayer.module.css';

const WistiaPlayer = dynamic(
  () => import('@wistia/wistia-player-react').then((mod) => mod.WistiaPlayer),
  {
    ssr: false,
  },
);

const WistiaVideoPlayer = ({
  className,
  title,
  thumbnail,
  thumbnailClassName,
  id = 'r5d3j2nz4m',
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div className={cn(styles.root, className)}>
      {isPlaying ? (
        <div
          className={cn(styles.videoWrapper, {
            [styles.videoWrapperPlaying]: isPlaying,
          })}
        >
          <WistiaPlayer
            mediaId={id}
            aspect={16 / 9}
            autoPlay={true}
            swatch={false}
          />
        </div>
      ) : (
        <button
          className={cn(styles.thumbnailContainer, thumbnailClassName)}
          aria-label="Play Video"
          onClick={() => setIsPlaying(true)}
        >
          {thumbnail &&
            (typeof thumbnail === 'string' ? (
              <Image
                src={thumbnail}
                alt={title}
                fill
                className={cn(styles.thumbnail)}
              />
            ) : (
              <SanityImage
                asset={thumbnail}
                alt={title}
                fill
                className={cn(styles.thumbnail)}
              />
            ))}
          <span className={cn(styles.playButton)}>
            <PlayIcon />
          </span>
        </button>
      )}
    </div>
  );
};

WistiaVideoPlayer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  thumbnailClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default WistiaVideoPlayer;
