import React from 'react';
import cn from 'classnames';

import TextRenderer, { RichTextRenderer } from '@components/TextRenderer';

import styles from './RichText.module.css';
import flowStyles from '@styles/utilities/flow.module.css';
import textStyles from '@styles/textStyles.module.css';
import measureStyles from '@styles/utilities/measures.module.css';

export const RichText = ({
  text,
  eyebrow,
  title,
  titleTag = 'h3',
  textSize = 'medium',
  measure = 'medium',
}) => {
  const textMeasureStyles = cn({
    [measureStyles.narrow]: measure === 'narrow',
    [measureStyles.medium]: measure === 'medium',
    [measureStyles.wide]: measure === 'wide',
    [measureStyles.article]: measure === 'article',
  });

  const textClassName = cn(
    {
      [textStyles.bodyM]: textSize === 'small',
      [textStyles.bodyL]: textSize === 'medium',
      [textStyles.headlineS]: textSize === 'large',
    },
    textMeasureStyles,
    flowStyles.root,
    flowStyles.small,
  );

  const titleClassName = cn(
    {
      [textStyles.headlineXS]: textSize === 'small',
      [textStyles.headlineS]: textSize === 'medium',
      [textStyles.headlineM]: textSize === 'large',
    },
    textMeasureStyles,
  );

  return (
    <div className={styles.root}>
      {eyebrow && <p className={textStyles.eyebrow}>{eyebrow}</p>}
      {title && (
        <TextRenderer
          as={titleTag}
          className={cn(titleClassName, styles.title)}
          text={title}
        />
      )}
      <RichTextRenderer
        text={text}
        className={textClassName}
        strongAsGradient={false}
      />
    </div>
  );
};
