import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import textStyles from '@styles/textStyles.module.css';
import * as styles from './TestimonialCard.module.css';

import ArrowLink from '@components/ArrowLink';
import BaseCard from '@components/BaseCard';
import Image from '@components/Image';
import SanityImage from '@components/SanityImage';
import TextRenderer from '@components/TextRenderer';

const TestimonialCard = ({
  quote,
  quoteSize = 'medium',
  author,
  authorTitle,
  companyLogo,
  companyName,
  href,
  gradient,
}) => {
  const quoteTextClasses = cn(
    {
      [textStyles.headlineXS]: quoteSize === 'medium',
      [textStyles.headlineS]: quoteSize === 'large',
    },
    styles.quote,
  );

  return (
    <BaseCard
      className={styles.root}
      tag="figure"
      theme="light"
      gradient={gradient}
    >
      <blockquote className={quoteTextClasses}>
        <TextRenderer text={`“${quote}”`} />
      </blockquote>
      <figcaption className={styles.author}>
        <div>
          <div className={styles.logoWrapper}>
            {companyLogo.src != null ? (
              <Image
                className={styles.logo}
                src={companyLogo.src}
                alt={companyLogo.alt}
                fill
                sizes="60px"
              />
            ) : (
              <SanityImage
                className={styles.logo}
                asset={companyLogo.image.asset}
                alt={companyLogo.alt}
                fill
                sizes="60px"
              />
            )}
          </div>
          <div className={styles.name}>{author}</div>
          <footer className={styles.footer}>
            {authorTitle && (
              <div className={styles.position}>{authorTitle}</div>
            )}
            {companyName && <div className={styles.company}>{companyName}</div>}
          </footer>
        </div>
        {href && (
          <div className={styles.linkWrapper}>
            <ArrowLink href={href} text="Read case study" />
          </div>
        )}
      </figcaption>
    </BaseCard>
  );
};

TestimonialCard.propTypes = {
  quote: PropTypes.string.isRequired,
  quoteSize: PropTypes.oneOf(['small', 'large']),
  author: PropTypes.string.isRequired,
  authorTitle: PropTypes.string,
  companyLogo: PropTypes.object.isRequired,
  href: PropTypes.string,
};

export default TestimonialCard;
