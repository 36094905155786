import cn from 'classnames';
import TextRenderer from '@components/TextRenderer';
import Actions from '@components/Actions';
import SanityImage from '@components/SanityImage';

import styles from './AwardListing.module.css';
import textStyles from '@styles/textStyles.module.css';

export const AwardListing = ({ title, actions, companyImage, awardImages }) => {
  return (
    <div className={styles.root}>
      <div className={styles.companyImage}>
        <SanityImage
          asset={companyImage.image.asset}
          alt={companyImage.alt}
          sizes="80px"
        />
      </div>

      <div className={styles.text}>
        <TextRenderer
          text={title}
          className={cn(textStyles.headlineS, styles.title)}
        />
        <Actions actions={actions} overwrites={{ colorScheme: 'text' }} />
      </div>

      <div className={styles.awardImages}>
        {awardImages.map((awardImg, i) => (
          <SanityImage
            key={i}
            asset={awardImg.image.asset}
            alt={awardImg.alt}
            maxWidth={95}
            maxHeight={130}
          />
        ))}
      </div>
    </div>
  );
};
