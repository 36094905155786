import cn from 'classnames';

import Icon from '@components/Icon';
import TextRenderer from '@components/TextRenderer';
import { withPageSection } from '@components/PageSection';

import styles from './InfoTiles.module.css';
import gridStyles from '@styles/layouts/grid.module.css';
import textStyles from '@styles/textStyles.module.css';

export const InfoTiles = withPageSection(
  ({ items, cols = 4, renderHeadlineAs = 'h4' }) => {
    const Headline = renderHeadlineAs;

    const style = {
      '--column-span-medium': 6,
      '--column-span-large': 12 / cols,
    };

    return (
      <div className={cn(styles.root, gridStyles.row)}>
        {items.map((item, i) => (
          <div
            key={i}
            className={cn(styles.tile, gridStyles.column)}
            style={style}
          >
            <Headline className={cn(styles.headline, textStyles.headlineXS)}>
              <Icon icon={item.icon} />
              {item.title}
            </Headline>
            <TextRenderer
              className={cn(styles.description, textStyles.bodyM)}
              text={item.description}
            />
          </div>
        ))}
      </div>
    );
  },
);
