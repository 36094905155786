import React from 'react';
import cn from 'classnames';

import Link from '@components/Link';
import AnimateIn from '@components/AnimateIn';
import BaseCard from '@components/BaseCard';
import Icon from '@components/Icon';
import TextRenderer from '@components/TextRenderer';
import { withPageSection } from '@components/PageSection';

import textStyles from '@styles/textStyles.module.css';
import * as styles from './ContactCards.module.css';

export const ContactCards = withPageSection(
  ({ cards, emailAddresses, postal }) => {
    return (
      <div className={styles.root}>
        <AnimateIn>
          {cards.map((card, i) => (
            <Link key={i} href={card.href} className={styles.link}>
              <BaseCard
                className={cn(styles.card, {
                  [styles.cardPrimary]: card.highlight,
                })}
              >
                <Icon className={styles.cardIcon} icon={card.icon} />
                <h2 className={cn(styles.cardTitle, textStyles.headlineXS)}>
                  {card.title}
                </h2>
                <TextRenderer
                  className={cn(styles.cardText, textStyles.bodyM)}
                  text={card.text}
                />
                <span className={styles.cardAction}>{card.action}</span>
              </BaseCard>
            </Link>
          ))}
          <div className={styles.additionalDetails}>
            <h2 className={cn(textStyles.eyebrow)}>{emailAddresses.title}</h2>
            <TextRenderer
              className={styles.additionalDetailsIntro}
              text={emailAddresses.intro}
            />

            <ol className={styles.emailList}>
              {emailAddresses.emails.map((email, i) => {
                return (
                  <li key={i}>
                    <Link
                      className={styles.emailLink}
                      href={`mailto: ${email.email}`}
                    >
                      <span className={styles.emailAddress}>{email.email}</span>
                      <span className={styles.emailCategory}>
                        {email.category}
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ol>
          </div>

          <div className={styles.additionalDetails}>
            <h2 className={cn(textStyles.eyebrow)}>{postal.title}</h2>
            <TextRenderer
              className={styles.additionalDetailsIntro}
              text={postal.intro}
            />

            <div
              className={styles.postalAddress}
              itemScope
              itemType="https://schema.org/Organization"
            >
              <div itemType="name">{postal.address.company}</div>
              <div
                itemProp="address"
                itemType="https://schema.org/PostalAddress"
                itemScope
              >
                <div itemProp="streetAddress">
                  {postal.address.addressAddon}
                  <br />
                  {postal.address.street}
                </div>
                <div itemProp="addressLocality">{postal.address.city}</div>
                <div itemProp="postalCode">{postal.address.postalCode}</div>
              </div>
            </div>
          </div>
        </AnimateIn>
      </div>
    );
  },
  {
    layout: 'horizontal',
  },
);
