import cn from 'classnames';

import AnimateIn from '@components/AnimateIn';
import CardWithIcon from '@components/CardWithIcon';
import { withPageSection } from '@components/PageSection';

import flowStyles from '@styles/utilities/flow.module.css';

export const CardList = withPageSection(
  ({ items }) => {
    return (
      <div className={cn(flowStyles.root, flowStyles.small)}>
        <AnimateIn>
          {items.map((item, index) => (
            <CardWithIcon
              key={index}
              heading={item.title}
              text={item.description}
              icon={item.icon}
              items={item.listItems}
              actions={item.actions}
            />
          ))}
        </AnimateIn>
      </div>
    );
  },
  {
    layout: 'horizontal',
  },
);
