import CardWithTickList from '@components/CardWithTickList';
import Carousel from '@components/Carousel';
import TwitterCard from '@components/TwitterCard';
import TestimonialCard from '@components/TestimonialCard';
import TestimonialCardWide from '@components/TestimonialCardWide';
import { withPageSection } from '@components/PageSection';

export const CardCarousel = withPageSection(
  ({ animateCardsIn = true, cards, eyebrow, heading, stretchCards = true }) => {
    if (cards.length === 0) {
      return null;
    }

    let items;

    const cardWidth =
      cards[0]?._type === 'testimonialCarouselCardWide' ? 'wide' : 'medium';
    const cardType = cards[0]?._type;

    switch (cardType) {
      case 'twitterCarouselCard':
        items = cards.map((item, index) => (
          <TwitterCard key={index} {...item} />
        ));
        break;
      case 'tickListCarouselCard':
        items = cards.map((item, index) => (
          <CardWithTickList
            key={index}
            heading={item.title}
            text={item.description}
            items={item.listItems}
          />
        ));
        break;
      case 'testimonialCarouselCard':
        items = cards.map((item, index) => {
          return <TestimonialCard key={index} {...item} />;
        });
        break;
      case 'testimonialCarouselCardWide':
        items = cards.map((item, index) => {
          return <TestimonialCardWide key={index} {...item} />;
        });
        break;
      default:
        items = [];
    }

    const carouselStyle =
      cardWidth === 'wide'
        ? {
            '--carousel-item-width': 'min(var(--layout-max-width-s), 90vw)',
          }
        : undefined;

    return (
      <Carousel
        animate={animateCardsIn}
        heading={heading}
        eyebrow={eyebrow}
        stretch={stretchCards}
        style={carouselStyle}
      >
        {items}
      </Carousel>
    );
  },
);
